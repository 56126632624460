import React, { useState, useEffect } from 'react'
import { graphql } from "gatsby"
import Layout from "../components/layout"
import debounce from "lodash.debounce"
import SearchForm from '../components/searchForm';
import SearchResults from '../components/searchResults';
import SEO from "../components/seo"
import search_page from '../../content/assets/page_images/search_page.webp'

const Search = (props) => {
  const {data, location} = props;
  const [results, setResults] = useState([]);
  const searchQuery = new URLSearchParams(location.search).get('keywords') || '';
  const frontmatter = {
    slug: props.location.pathname.replace(/[^\w\d-]/g, '')
  };
  useEffect(() => {
    if (searchQuery && window.__LUNR__) {
      const debouncedSearch = debounce(async () => {
        const lunr = await window.__LUNR__.__loaded
        const refs = lunr.en.index.search(searchQuery)
        const posts = refs.map(({ ref }) => lunr.en.store[ref])

        setResults(posts)
      }, 500)

      debouncedSearch()
    }

    if (!searchQuery) setResults([])
  }, [location.search])
  return(
    <Layout location={location} title={data.site.siteMetadata.title} slogan={data.site.siteMetadata.slogan}>
      <main>
        <SEO
          title="Search the PW Blog"
          description="Discover new interesting articles from our blog. We post on topics such as: electric vehicles, battery management systems, electric motors and many others..."
          image={search_page}
          frontmatter={frontmatter}
        />
        <h1 className="blog-heading">Search</h1>
        <p>Here you can filter blog posts on a given keyword. If you didn't find what you were searching for, please reach out in the contacts section of this website.</p>
        <p>We can help with any question related to e-bikes and PowerWatcher in particular.</p>
        <SearchForm query={searchQuery}/>
        <SearchResults query={searchQuery} results={results}/>
      </main>
    </Layout>
  )
}

export default Search

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        slogan
      }
    }
  }
`